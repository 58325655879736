import {
  AppInsightsErrorBoundary,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import React from "react";

import { Error } from "@/components/ui/Error";

type Props = {
  children: JSX.Element;
};

const reactPlugin = new ReactPlugin();

export const appInsights = new ApplicationInsights({
  config: {
    connectionString: `InstrumentationKey=${process.env.NEXT_PUBLIC_APPINSIGHTS_IKEY};IngestionEndpoint=https://centralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/`,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});
appInsights.loadAppInsights();

const AppInsights = ({ children }: Props) => {
  return (
    <AppInsightsErrorBoundary
      onError={() => <Error />}
      appInsights={reactPlugin}
    >
      {children}
    </AppInsightsErrorBoundary>
  );
};

export default withAITracking(reactPlugin, AppInsights);
